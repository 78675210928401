<template>
  <el-row id="article-list">
    <el-col :md="24">
        <el-row style="margin-bottom: 20px;">
          <el-col :md="24">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">喵世纪</el-breadcrumb-item>
              <el-breadcrumb-item>喵见闻-出去玩</el-breadcrumb-item>
            </el-breadcrumb>
          </el-col>
        </el-row>
      <el-row>
<!--                    @moving="syncMapCenterAndZoom"
            @moveend="syncMapCenterAndZoom"
            @zoomend="syncMapCenterAndZoom"-->
        <baidu-map
            ref="baiduMap"
            class="is-map"
            ak="BTOGRUnREy1Wm4stENaU7EB9IcfG2cS3"
            :center="map.center"
            :zoom="map.zoom"
            @ready="onMapReady"

        >
        </baidu-map>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-collapse v-model="activeName" accordion style="width: calc(100% - 30px);">
            <el-collapse-item title="最近" name="1">
              <el-table :data="tableData" stripe style="" @row-click="onTableRowClick">
                <el-table-column prop="date" label="日期" width="180"> </el-table-column>
                <el-table-column prop="title" label="标题"> </el-table-column>
              </el-table>
            </el-collapse-item>
            <el-collapse-item title="近一个月" name="2">
              <el-table :data="tableData" stripe style="">
                <el-table-column prop="date" label="日期" width="180"> </el-table-column>
                <el-table-column prop="title" label="标题"> </el-table-column>
              </el-table>
            </el-collapse-item>
            <el-collapse-item title="近一年" name="3">
              <el-table :data="tableData" stripe style="">
                <el-table-column prop="date" label="日期" width="180"> </el-table-column>
                <el-table-column prop="title" label="标题"> </el-table-column>
              </el-table>
            </el-collapse-item>
            <el-collapse-item title="更多" name="4">
              <el-table :data="tableData" stripe style="">
                <el-table-column prop="date" label="日期" width="180"> </el-table-column>
                <el-table-column prop="title" label="标题"> </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
    </el-col>
  </el-row>

</template>

<script>

import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
export default {
  name: "ArticleList",
  components: {BaiduMap,},
  data(){
    return {
      map: {
        keyword: '',
        center: {
          lat: 39.910924,
          lng: 116.413387,
        },
        city: '北京',
        zoom: 25,
        suggestionTimeout: null,
      },
      count: 0,
      tableData: [
        {
          date: '2020-05-02',
          title: '测试标题',
          url: '/article-view',
        },
        {
          date: '2020-05-02',
          title: '测试标题',
          url: '/article-view',
        },
        {
          date: '2020-05-02',
          title: '测试标题',
          url: '/article-view',
        },
        {
          date: '2020-05-02',
          title: '测试标题',
          url: '/article-view',
        },
      ],
      activeName: '1',
    }
  },
  methods: {
    load () {
      this.count += 2
    },
    onTableRowClick(row){
      console.log(row.url, 'onTableRowClick')
      if (row.url !== this.$router.currentRoute.path){
        this.$router.push({path: row.url})
      }
    },
    onMapReady({BMap, map}){
      console.log('ready', BMap, map)
      map.setMapStyleV2({
        styleId: '9d15194aa72a4a73c92d75f059817172'
      })
    },
  },
}
</script>

<style scoped>
#article-list{
  margin: 30px 50px 50px;
}

.is-map{
  width: calc(100% - 30px);
  height: 300px;
}
</style>
