<template>
  <el-carousel arrow="always" :height="height + 'px'">
<!--    :interval="5000"-->
    <el-carousel-item v-for="item in 4" :key="item" >
<!--      <h3>{{ item }}</h3>-->
      <el-image  style="width: 100%; height: 100%" fit="scale-down" :src="'http://qyhkp77h5.hb-bkt.clouddn.com/' + item + '.png/image.png'" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "IceSunGallery",
  props: {
    height: Number,
  },
  data(){
    return {
      timer: null,
      // timerCount: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScrolled)
  },
  methods: {
    onScrolled(){
      this.debounce(this.goAnchor, 1000)
      //console.log(this.timerCount,this.timer)
      //console.log(document.getElementById('gallery'))
    },
    goAnchor() {
      //console.log("goAnchor")
      //document.documentElement.scrollTop = document.getElementById('gallery').offsetTop
      let element = document.getElementById('gallery')
      let offsetTop = element.offsetTop
      while (element.offsetParent !== null){
        element = element.offsetParent
        offsetTop += element.offsetTop
      }

      if (document.documentElement.scrollTop - offsetTop < 50
          && document.documentElement.scrollTop - offsetTop > -50
          && document.documentElement.scrollTop - offsetTop !== 0
      ) {
        // this.timerCount ++;
        // console.log("add")
        // console.log(this.timer)
        document.documentElement.scrollTop = offsetTop
      }
      else {
        // this.timerCount = 0;
        clearTimeout(this.timer)
        // console.log("zero")
        // console.log(this.timer)
      }

      // if (this.timerCount >= 3){
      //   document.documentElement.scrollTop = offsetTop
      //   //console.log("ok")
      // }


    },
    debounce(fn,wait){
      if(this.timer !== null){
        clearTimeout(this.timer);
        //console.log("reset")

      }

      this.timer = setTimeout(fn,wait);
      // console.log('run timeout')

    }
  },
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #cecece;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #cecece;
}
</style>
