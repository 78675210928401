import Vue from 'vue'
import App from './App.vue'

import '@/assets/css/app.css'

import '@/assets/css/font.css'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.config.productionTip = false


//IceSunNote: 2021/9/9 12:11 vue-router start
import VueRouter from 'vue-router'
import Index from '@/components/Index'
import ArticleEdit from "@/components/ArticleEdit";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index',

  },
  {
    path: '/index',
    component: Index,
    meta: {
      menuItemIndex: '1',
    }
  },
  {
    path: '/article-edit',
    component: ArticleEdit,
    meta: {
      menuItemIndex: '3',
    }
  },
  {
    path: '/article-list',
    component: ArticleList,
    meta: {
      menuItemIndex: '2-1'
    }
  },
  {
    path: '/article-view',
    component: ArticleView,
    meta: {
      menuItemIndex: '2-1-x'
    },
  },

  {
    path: '/resume/:token',
    component: Resume,
    meta: {
      menuItemIndex: '0'
    },
  },
  {
    path: '/resume',
    component: Resume,
    meta: {
      menuItemIndex: '0'
    },
  }
]

const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})

//IceSunNote: 2021/9/9 12:11 vue-router end


//IceSunNote: 2021/9/9 12:18 element-ui start
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'element-ui/lib/theme-chalk/display.css';
//IceSunNote: 2021/9/2 9:56 断点隐藏, 响应式布局

Vue.use(ElementUI)

//IceSunNote: 2021/9/9 12:19 element-ui end


//IceSunNote: 2021/9/9 13:38 axios start

import axios from "axios";
import ArticleView from "@/components/ArticleView";
import ArticleList from "@/components/ArticleList";
import Resume from "@/components/Resume";

Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios

//IceSunNote: 2021/9/9 13:39 axios end




new Vue({
  router, //IceSunNote: 2021/9/9 12:13 vue-route
  render: h => h(App),
}).$mount('#app')
