<template>
  <el-row id="resume">
    <el-col id="resume-content">
      <el-row>
        <el-col class="hidden-sm-and-down" :md="2" :lg="6" :xl="8">
          <span class="font-hidden">喵喵喵</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="12" :xl="8">
          <el-descriptions title="基本信息" :column="2" border>
            <el-descriptions-item v-for="(value, key) in data.basic" :key="key" :label="key" :span="2">
              <span v-html="value"></span>
            </el-descriptions-item>
          </el-descriptions>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="hidden-sm-and-down" :md="2" :lg="6" :xl="8">
          <span class="font-hidden">喵喵喵</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="12" :xl="8">
          <el-descriptions title="综述" :column="2" border>
            <el-descriptions-item v-for="(value, key) in data.overview" :key="key" :label="key" :span="2" label-class-name="my-label" content-class-name="my-content">
              <span v-html="value"></span>
            </el-descriptions-item>

          </el-descriptions>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="hidden-sm-and-down" :md="2" :lg="6" :xl="8">
          <span class="font-hidden">喵喵喵</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="12" :xl="8">
          <el-descriptions title="教育经历" :column="2" border>
            <el-descriptions-item v-for="(value, key) in data.education" :key="key" :label="key" :span="2" label-class-name="my-label" content-class-name="my-content">
              <span v-html="value"></span>
            </el-descriptions-item>

          </el-descriptions>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="hidden-sm-and-down" :md="2" :lg="6" :xl="8">
          <span class="font-hidden">喵喵喵</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="12" :xl="8">
          <div class="el-descriptions__header">
            <div class="el-descriptions__title" >
              工作经历
            </div>
          </div>
          <div>
            <el-timeline align="left" class="resume-timeline-body">
              <el-timeline-item v-for="(value, key) in data.work" :key="key" :timestamp="value.beginDate + ' ~ ' + value.endDate" placement="top">
                <el-card>
                  <div slot="header" class="clearfix">
                    <h4>{{value.name}}</h4>
                    <p>
                      <el-tag v-for="tip in value.tips" :key="tip">{{tip}}</el-tag>
                    </p>
                  </div>
                  <p>
                    {{value.content}}
                  </p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col class="hidden-sm-and-down" :md="2" :lg="6" :xl="8">
          <span class="font-hidden">喵喵喵</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="20" :lg="12" :xl="8">
          <div class="el-descriptions__header">
            <div class="el-descriptions__title" >
              项目经历
            </div>
          </div>
          <div>
            <el-timeline align="left" class="resume-timeline-body">
              <el-timeline-item v-for="(value, key) in data.project" :key="key" :timestamp="value.beginDate + ' ~ ' + value.endDate" placement="top">
                <el-card>
                  <div slot="header" class="clearfix">
                    <h4>{{value.name}}</h4>
                    <div>
                      <el-tag v-for="(tip, key) in value.tips" :key="key">{{tip}}</el-tag>
                    </div>
                  </div>
                  <p v-html="value.content">
                  </p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>

const names = {
  birthday: "出生年月",
  email: "Email",
  experienceTime: "工作经验",
  maritalStatus: "婚姻状况",
  name: "姓名",
  nowLocation: "所在地",
  phoneNumber: "TEL",
  registerLocation: "户籍所在地",
  sex: "性别",
  website: "个人网站",

}

export default {
  name: "Resume",
  data(){
    return {
      token: "",
      data: {
        basic: {

        },
        overview: {

        },
        education: {

        },
        work: {

        },
        project: {

        },
        other: {

        },
      },
    }
  },
  methods: {
    errorMessage(content) {
      // const h = this.$createElement;

      this.$notify.error({
        title: '警告',
        message: content,
        dangerouslyUseHTMLString: true,
        duration: 0,
      });
    },
    successMessage(content) {
      // const h = this.$createElement;

      this.$notify.success({
        title: '提示',
        message: content,
        dangerouslyUseHTMLString: true,
        duration: 10000,
      });
    },
    testBackend(){
      const that = this
      this.$axios.get("/backend/resume/test?token={token}"
          .replace("{token}", encodeURIComponent(that.token))).then(()=>{setTimeout(that.testBackend, 15000)}).catch(() => {
        that.$router.go(0)
      })
    },
  },
  created() {
  },
  mounted() {
    const that = this
    if (this.$router.currentRoute.params.token){
      this.token = this.$router.currentRoute.params.token
    }
    // console.log(encodeURIComponent(this.token))
    this.$axios.get("/backend/resume/detail?token={token}".replace("{token}", encodeURIComponent(this.token))).then(({data, headers})=>{
      // console.log("get", data)
      // const data = res.data
      // console.log(res.headers)
      if (headers["error-code"] && headers['error-code']){
        let postfix = ''
        let prefix = ''
        if (headers["begin-date"] && headers["end-date"] && headers['interviewer-name']){
          prefix = '抱歉, {name}<br>'.replace("{name}", decodeURIComponent(headers['interviewer-name']))
          postfix = '<br>{begin}<br>到<br>{end}'
              .replace("{begin}", headers["begin-date"])
              .replace("{end}", headers["end-date"])
        }

        this.errorMessage(prefix + decodeURIComponent(headers['error-msg']) + postfix)
        return
      }


      Object.keys(data).forEach(key=>{
        // console.log(key, data[key], typeof data[key])
        if (typeof data[key] == "string"){
          that.data.basic[Object.keys(names).includes(key) ? names[key] : key] = data[key]
          that.$forceUpdate()
        }
        else {
          that.data[key] = data[key]

        }
      })
      this.successMessage("欢迎 {name} 查看我的简历, <br>简历查看有效期为: <br>{begin}<br>到<br>{end}"
          .replace("{name}", decodeURIComponent(headers['interviewer-name']))
          .replace("{begin}", headers["begin-date"])
          .replace("{end}", headers["end-date"]))

      setTimeout(that.testBackend, 15000)
    }).catch(() => {
      let errorMsg = '未知错误'
      // try {
      //   errorMsg = JSON.parse(reason.response.data.message).msg
      // } catch (e){
      //   errorMsg = "未知错误"
      // }
      // that.errorMsg = errorMsg
      // console.log(reason)
      this.errorMessage(errorMsg)
    }).finally(()=>{
      // console.log(that.data)


    })
  },

}
</script>

<style scoped>

#resume{
  margin: 50px 20px 50px;
}


/deep/ .my-label{
  min-width: 100px;
}

#resume-content>.el-row{
  margin-top: 20px;
}

.resume-timeline-body .el-tag{
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
