var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticStyle:{"width":"100%"}},[_c('el-col',{attrs:{"xl":24,"lg":24,"md":24,"sm":24,"xs":24}},[_c('div',{style:({
      minHeight: Math.max(_vm.width*10/16, Math.min(_vm.width*3/4, _vm.height)) + 'px',
      width: '100%', backgroundColor: '#909399',
      color: 'white',

    })},[_c('div',{style:({
        padding: '10% 10%'
      })},[_c('IceSunBarrageX',{attrs:{"send-val":_vm.getRandomBarrageArr()}}),_c('IceSunBarrageX',{attrs:{"send-val":_vm.getRandomBarrageArr()}}),_c('IceSunBarrageX',{attrs:{"send-val":_vm.getRandomBarrageArr()}}),_c('h1',{staticStyle:{"font-size":"50px"}},[_vm._v("Meow Food")]),_c('p',[_vm._v("两只猫的吃货之旅, Meow~")])],1)]),_c('IceSunGallery',{attrs:{"height":_vm.height,"id":"gallery"}}),_c('IceSunTimeLine',{attrs:{"width":_vm.width,"height":_vm.height}}),_c('SizeUtil',{on:{"onWindowResize":_vm.onWindowResize}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }