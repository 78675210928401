<template>
  <el-row id="article-edit">
    <el-col class="hidden-sm-and-down" :md="2" :lg="5" :xl="2">
      <span class="font-hidden">喵喵喵</span>
    </el-col>
    <el-col :xl="22" :lg="15" :md="20" :sm="22" :xs="22">
      <el-form class="is-form">
        <!--  标题  -->
        <el-row class="is-row" :gutter="10">
          <el-col :xl="16" :lg="15" :md="24" :sm="24" :xs="24">
            <h2>也许是个大新闻</h2>
          </el-col>
        </el-row>
        <el-row class="is-row" :gutter="10">
          <el-col :xl="2" :lg="3" :md="4" :sm="4" :xs="5">
            <el-container>
              <span class="is-row-prefix">标题:</span>
            </el-container>
          </el-col>
          <el-col :xl="14" :lg="16" :md="20" :sm="20" :xs="19">
            <el-input class="is-input" v-model="form.articleTitle" placeholder="该起个什么惊世骇俗的UC标题呢?">
            </el-input>
          </el-col>

          <!--   分类   -->
        </el-row>
        <el-row class="is-row" :gutter="10">
          <el-col :xl="2" :lg="3" :md="4" :sm="4" :xs="5">
            <el-container>
              <span class="is-row-prefix">分类:</span>
            </el-container>
          </el-col>
          <el-col :xl="14" :lg="16" :md="20" :sm="20" :xs="19">
            <el-container>
              <el-select class="is-input" v-model="classifyValue" placeholder="什么样的脑洞? " style="width: 100%;">
                <template slot="empty" >
                  <p class="el-select-dropdown__empty" style="font-family: 快乐体;">咦, 未知脑洞</p>
                </template>
                <el-option
                    style="font-family: 快乐体;"
                    v-for="item in classifyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">

                </el-option>
              </el-select>
            </el-container>
          </el-col>
        </el-row>


        <!--  标签  -->
        <el-row class="is-row" :gutter="10">
          <el-col :xl="2" :lg="3" :md="4" :sm="4" :xs="5">
            <el-container>
              <span class="is-row-prefix" @click="showInput">标签:</span>
            </el-container>
          </el-col>
          <el-col :xl="14" :lg="16" :md="20" :sm="20" :xs="19">
            <!--        <el-container>-->
            <!--          <div class="is-input el-input el-input-group el-input-group&#45;&#45;prepend" >-->
            <el-container style="flex-wrap: wrap;">
              <!--              <el-button class="button-new-tag is-row-prefix is-tag" @click="showInput">标 签:</el-button>-->
              <el-tag
                  class="is-tag"
                  :key="tag"
                  v-for="tag in form.articleTag"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)">
                {{tag}}
              </el-tag>
              <!--                  class="input-new-tag"-->
              <el-input
                  class="is-input"
                  style="width: 50%"
                  placeholder="新标签"
                  v-if="newTagInputVisible"
                  v-model="newTagInputValue"
                  ref="saveTagInput"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
              >
              </el-input>
            </el-container>
            <!--          </div>-->
            <!--        </el-container>-->
          </el-col>
        </el-row>

        <!--  位置  -->
        <!--    <el-row class="is-row">-->
        <!--      <el-col :xl="14" :lg="16" :md="24" :sm="24" :xs="24">-->
        <!--        <el-container style="flex-wrap: wrap; width: 100%;">-->
        <!--          <span class="is-row-prefix is-tag">位 置: </span>-->

        <!--          <span>-->
        <!--          <el-input placeholder="在哪呢? " v-model="map.keyword" >-->
        <!--            <el-select v-model="map.city" style="width: 100px;" slot="prepend" placeholder="请选择城市">-->
        <!--            <el-option label="北京" value="北京"></el-option>-->
        <!--            <el-option label="大连" value="大连"></el-option>-->
        <!--          </el-select>-->
        <!--            <el-button icon="el-icon-search" slot="append" @click="onMapSearchBtnClick" ></el-button>-->
        <!--          </el-input>-->
        <!--        </span>-->
        <!--        </el-container>-->
        <!--      </el-col>-->
        <!--    </el-row>-->
        <el-row class="is-row" type="flex" justify="start" :gutter="10">
          <el-col :xl="2" :lg="3" :md="4" :sm="4" :xs="5">
            <el-container>
              <span class="is-row-prefix">经度:</span>
            </el-container>
          </el-col>
          <el-col :xl="14" :lg="16" :md="20" :sm="20" :xs="19">
            <el-container>
              <el-input class="is-input" v-model="map.center.lng" @change="logger">
              </el-input>
            </el-container>
          </el-col>


        </el-row>

        <el-row class="is-row" type="flex" justify="start" :gutter="10">
          <el-col :xl="2" :lg="3" :md="4" :sm="4" :xs="5">
            <el-container>
              <span class="is-row-prefix">纬度:</span>
            </el-container>
          </el-col>
          <el-col :xl="14" :lg="16" :md="20" :sm="20" :xs="19">
            <el-container>
              <el-input class="is-input" v-model="map.center.lat" @change="logger">
              </el-input>
            </el-container>
          </el-col>
        </el-row>
        <el-row class="is-row" type="flex" justify="start" :gutter="10">
          <el-col :xl="2" :lg="3" :md="4" :sm="4" :xs="5">
            <el-container>
              <span class="is-row-prefix">地图:</span>
            </el-container>
          </el-col>
          <el-col :xl="14" :lg="16" :md="20" :sm="20" :xs="19">
            <el-container>
              <el-autocomplete
                  class="is-input"
                  placeholder="在哪呢? "
                  style="width: 100%;"
                  :fetch-suggestions="fetchSuggestionsAsync"
                  v-model="map.keyword"
                  @select="logger"
              >
                <el-select v-model="map.city" style="width: 100px;" slot="prepend" placeholder="请选择城市">
                  <el-option style="font-family: 快乐体;" label="北京" value="北京"></el-option>
                  <el-option style="font-family: 快乐体;" label="大连" value="大连"></el-option>
                </el-select>
                <el-button icon="el-icon-search" slot="append" @click="onMapSearchBtnClick" ></el-button>
              </el-autocomplete>
            </el-container>
          </el-col>
        </el-row>
        <el-row class="is-row" :gutter="10">
          <el-col :xl="16" :lg="19" :md="24" :sm="24" :xs="24">
            <el-container>
              <baidu-map
                  ref="baiduMap"
                  class="is-map"
                  ak="BTOGRUnREy1Wm4stENaU7EB9IcfG2cS3"
                  :center="map.center"
                  :zoom="map.zoom"
                  @ready="onMapReady"
                  @moving="syncMapCenterAndZoom"
                  @moveend="syncMapCenterAndZoom"
                  @zoomend="syncMapCenterAndZoom"
              >
              </baidu-map>
            </el-container>
          </el-col>
        </el-row>
        <el-row class="is-row" :gutter="10">
          <el-col :xl="2" :lg="3" :md="4" :sm="4" :xs="5">
            <el-container>
              <span class="is-row-prefix">内容:</span>
            </el-container>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :xl="16" :lg="19" :md="24" :sm="24" :xs="24">
            <el-container>
              <size-util @onWindowResize="onWindowResize" />
              <ice-sun-multi-editor @onContentChange="onContentChange" :height=window.height :width=window.width />
            </el-container>
          </el-col>
        </el-row>


      </el-form>
    </el-col>
  </el-row>

</template>

<script>
import SizeUtil from "@/components/SizeUtil";
import IceSunMultiEditor from "@/components/IceSunMultiEditor";
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
// import { BmlMarkerClusterer } from 'vue-baidu-map'
export default {
  name: "ArticleEdit",
  // eslint-disable-next-line vue/no-unused-components
  components: {IceSunMultiEditor, SizeUtil, BaiduMap},
  data(){
    return {
      map: {
        keyword: '',
        center: {
          lat: 39.910924,
          lng: 116.413387,
        },
        city: '北京',
        zoom: 25,
        suggestionTimeout: null,
      },
      location: '',
      window: {
        height: 0,
        width: 0,
      },
      form: {
        articleTitle: '',
        articleTag: [],
        locale_lat: 39.910924,
        locale_lng: 116.413387,
        articleContent: '',
      },
      newTagInputVisible: true,
      newTagInputValue: '',


      classifyOptions: [{
        value: '出去吃',
        label: '出去吃',
      }, {
        value: '出去玩',
        label: '出去玩',
      }, {
        value: '在家吃',
        label: '在家吃',
      },],
      classifyValue: ''
    }
  },

  watch: {
    'map.center.lat'(newValue){
      this.form.locale_lat = newValue
    },
    'map.center.lng'(newValue){
      this.form.locale_lng = newValue
    },
  },

  methods: {
    onWindowResize(h, w){
      this.window.height = h
      this.window.width = w
      // console.log('Window Resize', h, w)
    },
    async onMapSearchBtnClick(){
      console.log(this.map)
      const {data, status} = await this.$axios.get("/baidu-map-api/place/v2/suggestion?query=" + this.map.keyword + "&region=" + this.map.city + "&city_limit=false&output=json&ak=BTOGRUnREy1Wm4stENaU7EB9IcfG2cS3");
      if (status === 200){
        console.log(data.result)
        this.map.center = data.result[0].location
        this.map.zoom = 25
        console.log(this.map.center)
      }
    },

    onMapReady({BMap, map}){
      console.log('ready', BMap, map)
      map.setMapStyleV2({
        styleId: '9d15194aa72a4a73c92d75f059817172'
      })
    },

    onContentChange(newValue){
      this.form.articleContent = newValue
      console.log(this.form)
    },






    handleClose(tag) {
      this.form.articleTag.splice(this.form.articleTag.indexOf(tag), 1);
    },

    showInput() {
      this.newTagInputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.newTagInputValue;
      if (inputValue) {
        this.form.articleTag.push(inputValue);
      }
      if (this.form.articleTag.length > 0){
        this.newTagInputVisible = false;
      }
      this.newTagInputValue = '';
    },

    syncMapCenterAndZoom(e){
      const {lng, lat} = e.target.getCenter()
      this.map.center.lng = lng
      this.map.center.lat = lat
      this.map.zoom = e.target.getZoom()
    },

    fetchSuggestionsAsync(queryString, cb) {
      const that = this
      if (queryString){
        that.$axios.get(
            "/baidu-map-api/place/v2/suggestion" +
            "?query=" + queryString +
            "&region=" + that.map.city +
            "&city_limit=false&output=json" +
            "&ak=BTOGRUnREy1Wm4stENaU7EB9IcfG2cS3").then(({data, status})=>{
          let dataResult = []
          if (status === 200){
            console.log(data.result)
            that.map.zoom = 25
            dataResult = data.result
            console.log(that.map.center)
          }
          let result = that.getNameFromData(dataResult)
          console.log(result, 777)
          cb(result);
        })
      }

    },

    getNameFromData(dataResult){
      dataResult.forEach(i=>{
        i.value = i.name
      })
      console.log(dataResult)
      return dataResult
    },

    logger(value){
      console.log("log", value)
    },


  },
}
</script>

<style scoped>
/*
/deep/ .is-input .el-input__inner => el-input的placeholder
/deep/ .ql-blank::before => quill的data-placeholder
*/
/deep/ .is-input .el-input__inner, /deep/ .ql-blank::before, h2 {
    font-family: 快乐体 !important;
}

.is-row {
  padding: 5px 5px 5px 5px;
}

.is-row-prefix {
  background-color: #F5F7FA;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 14px;
  width: 100%;
  height: 38px;
  line-height: 38px;
  margin: 0 0 10px 0;
  font-family: 快乐体;
}
.is-tag {
  height: 38px;
  font-family: 快乐体;
  vertical-align: middle;
  display: table-cell;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 38px;
  margin: 0 5px 10px 0;
}
.is-map{
  width: 100%;
  height: 300px;
}

.is-form{
  padding: 0 10px 100px 10px;
}
#article-edit{
  margin-top: 70px;
  margin-bottom: 50px;
}
</style>
