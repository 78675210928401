<template>
  <el-container>
    <el-col class="hidden-sm-and-down" :md="2" :lg="4" :xl="6">
      <span class="font-hidden">喵喵喵</span>
    </el-col>
    <el-col :xl="10" :lg="14" :md="18" :sm="22" :xs="22">
      <div :style="{
        // minHeight: Math.max(width*10/16, Math.min(width*3/4, height)) + 'px',
        // width: width + 'px',
        padding: '10%',
      }">
        <el-timeline style="text-align: left">
          <el-timeline-item timestamp="2021/4/12" placement="top">
            <el-card :style="{
            background: 'url(' + require('@/assets/img/timeline/background_' + randomInt(1, 4) + '.png') + ')' + ' no-repeat 95% 80% ',
            backgroundSize: 'auto 50%'
          }">
              <h3>测试某次</h3>
              <p>xxx</p>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2020/4/3" placement="top">
            <el-card :style="{
            background: 'url(' + require('@/assets/img/timeline/background_' + randomInt(1, 4) + '.png') + ')' + ' no-repeat 95% 80% ',
            backgroundSize: 'auto 50%'
          }">
              <h3>测试某次</h3>
              <p>xxx</p>
            </el-card>
          </el-timeline-item>
          <el-timeline-item timestamp="2020/4/2" placement="top">
            <el-card :style="{
            background: 'url(' + require('@/assets/img/timeline/background_' + randomInt(1, 4) + '.png') + ')' + ' no-repeat 95% 80% ',
            backgroundSize: 'auto 50%'
          }">
              <h3>测试某次</h3>
              <p>xxx</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>

    </el-col>
  </el-container>
</template>

<script>
export default {
  name: "IceSunTimeLine",
  props: {
    height: Number,
    width: Number,
  },
  data(){
    return {

    }
  },
  methods: {
    randomInt(min, max){
      return Math.round(Math.random()*(max - min) + min)
    },
  },
}
</script>

<style scoped>
</style>
