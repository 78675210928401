<template>
  <div id="app" >
    <IceSunHeader style="border-bottom: 0;" />
    <el-container>
      <router-view></router-view>
    </el-container>
    <IceSunFooter />
    <GoTop />
  </div>
</template>

<script>

import GoTop from "@/components/GoTop";
import IceSunHeader from "@/components/IceSunHeader";
import IceSunFooter from "@/components/IceSunFooter";
export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    IceSunFooter,
    IceSunHeader,
    GoTop
  },
  data(){
    return {


    }

  },


}
</script>

<!--suppress CssNoGenericFontName, CssUnusedSymbol -->
<style>
#app {
  font-family: 仓耳渔阳体3, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  /*margin-top: 60px;*/
}
html, body{
  margin: 0;
  padding: 0;
}
*{
  font-family: 仓耳渔阳体3;
}

.font-hidden{
  font-size: 0;
}

.el-container>.el-row{
  width: 100%;
}
</style>
