<template>
  <el-row id="article-view">
    <el-col class="hidden-sm-and-down" :md="2" :lg="5" :xl="2">
      <span class="font-hidden">喵喵喵</span>
    </el-col>
    <el-col :xl="22" :lg="15" :md="20" :sm="22" :xs="22" class="ql-editor">
        <p><span class="ql-size-large">测试文章标题</span></p><p><br></p>
        <p><span class="ql-size-huge">测试文章大字内容测试文章大字内容测试文章大字内容测试文章大字内容测试文章大字内容测试文章大字内容</span></p>
        <p><br></p>
        <p><a href="https://icesun.cn" rel="noopener noreferrer" target="_blank">测试文章链接测试文章链接测试文章链接</a></p>
        <p><br></p>
        <p><span class="ql-font-快乐体">测试文章快乐体内容测试文章快乐体内容</span></p>
    </el-col>
  </el-row>

</template>

<script>
export default {
  name: "ArticleView"
}
</script>

<style scoped>
#article-view{
  margin-top: 70px;
  margin-bottom: 50px;
}
</style>
