var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('el-col',{staticClass:"hidden-sm-and-down",attrs:{"md":2,"lg":4,"xl":6}},[_c('span',{staticClass:"font-hidden"},[_vm._v("喵喵喵")])]),_c('el-col',{attrs:{"xl":10,"lg":14,"md":18,"sm":22,"xs":22}},[_c('div',{style:({
      // minHeight: Math.max(width*10/16, Math.min(width*3/4, height)) + 'px',
      // width: width + 'px',
      padding: '10%',
    })},[_c('el-timeline',{staticStyle:{"text-align":"left"}},[_c('el-timeline-item',{attrs:{"timestamp":"2021/4/12","placement":"top"}},[_c('el-card',{style:({
          background: 'url(' + require('@/assets/img/timeline/background_' + _vm.randomInt(1, 4) + '.png') + ')' + ' no-repeat 95% 80% ',
          backgroundSize: 'auto 50%'
        })},[_c('h3',[_vm._v("测试某次")]),_c('p',[_vm._v("xxx")])])],1),_c('el-timeline-item',{attrs:{"timestamp":"2020/4/3","placement":"top"}},[_c('el-card',{style:({
          background: 'url(' + require('@/assets/img/timeline/background_' + _vm.randomInt(1, 4) + '.png') + ')' + ' no-repeat 95% 80% ',
          backgroundSize: 'auto 50%'
        })},[_c('h3',[_vm._v("测试某次")]),_c('p',[_vm._v("xxx")])])],1),_c('el-timeline-item',{attrs:{"timestamp":"2020/4/2","placement":"top"}},[_c('el-card',{style:({
          background: 'url(' + require('@/assets/img/timeline/background_' + _vm.randomInt(1, 4) + '.png') + ')' + ' no-repeat 95% 80% ',
          backgroundSize: 'auto 50%'
        })},[_c('h3',[_vm._v("测试某次")]),_c('p',[_vm._v("xxx")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }