<template>
  <div class="my-outbox">
    <div class="my-inbox" ref='box'>
      <div class="my-list" v-for="(item,index) in sendVal" :key='index'>
        {{item}}
      </div>
<!--      <div class="my-list" v-for="(item,index) in sendVal" :key='(index+1)*100'>-->
<!--        -->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "IceSunBarrageX",
  props:{
    sendVal:Array
  },
  data() {
    return {}
  },
  mounted:function(){
    // var that = this;
    const target = this.$refs.box;
    let initLeft = 0;
    setInterval(function(){
      initLeft ++;
      if(initLeft >= target.offsetWidth / 2 ){
        initLeft = 0;
      }
      target.style.transform = 'translateX(-'+ initLeft +'px)';
      //target.style = 'transform: translateX(-'+ initLeft +'px)';
    },20)
  },
}
</script>

<style scoped>
.my-outbox {
  color: #CCCCCC;
  overflow: hidden;
  height: 60px;
  /*background: #422b02;*/
  position: relative;
}
.my-outbox .my-inbox {
  white-space: nowrap;
  position: absolute;
  font-size: 0;
}
.my-outbox .my-inbox .my-list {
  margin-right: 200px;
  display: inline-block;
  font-size: 20px;
  height: 20px;
  line-height: 20px;
}
/*.my-outbox .my-inbox .my-list .my-uname {*/
/*  !*color: #FF8900;*!*/
/*}*/

</style>
