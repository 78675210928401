<template>
<div class="hidden"></div>
</template>

<script>
export default {
  name: "SizeUtil",
  data(){
    return {
      mWindowHeight: 0,
      mWindowWidth: 0,
    }
  },
  mounted() {
    const that = this
    that.mWindowHeight = window.innerHeight;
    that.mWindowWidth = window.innerWidth;
    console.log(1)
    that.$emit('onWindowResize', that.mWindowHeight, that.mWindowWidth)
    window.onresize = () => {
      that.mWindowHeight = window.innerHeight;
      that.mWindowWidth = window.innerWidth;
      that.$emit('onWindowResize', that.mWindowHeight, that.mWindowWidth)
      console.log(1)
    }
  }
}
</script>

<style scoped>

</style>