<template>
  <el-row class="is-footer" style="width: 100%; ">
    <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
      <div :style="{
        width: '100%', backgroundColor: '#303133',
        // padding: '10%',
        color: '#909399',
      }">
        <div :style="{
          padding: '10%',
        }" >
          <p>
            <span>友情链接：</span>
            <a href="https://www.icesun.cn/" target="_blank">IceSun CN</a>
            <el-divider direction="vertical"></el-divider>
            <a href="https://baidu.com/" target="_blank">Baidu</a>
            <el-divider direction="vertical"></el-divider>
            <a href="https://aliyun.com/">Aliyun</a>
          </p>
          <p>
            <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049408"
                style="display:inline-block;height:20px;line-height:20px;">
              <img alt="网安备" src="@/assets/img/beian-logo.png" style="float:left;"/>京公网安备 11010502049408号
            </a>
             |
            <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022008036号-2</a>
            Copyright© 2021-2022
            <a href="https://icesun.cn/" target="_blank" >IceSun</a>
            .All Rights Reserved.</p>
        </div>

        <SizeUtil @onWindowResize="onWindowResize" />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import SizeUtil from "@/components/SizeUtil";
export default {
  name: "IceSunFooter",
  components: {SizeUtil},
  data() {
    return {
      height: 0,
      width: 0,
    }
  },
  methods: {
    onWindowResize(h, w) {
      this.height = h
      this.width = w
      console.log('Window Resize', h, w)
    },
  }
}
</script>

<style scoped>
.is-footer a{
  color: #0e90d2;
  text-decoration: none;
}
</style>
