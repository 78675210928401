<template>
  <el-row style="width: 100%; ">
    <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
      <div :style="{
        minHeight: Math.max(width*10/16, Math.min(width*3/4, height)) + 'px',
        width: '100%', backgroundColor: '#909399',
        color: 'white',

      }">
        <div :style="{
          padding: '10% 10%'
        }" >
          <IceSunBarrageX :send-val="getRandomBarrageArr()" />
          <IceSunBarrageX :send-val="getRandomBarrageArr()" />
          <IceSunBarrageX :send-val="getRandomBarrageArr()" />
          <h1 style="font-size: 50px;">Meow Food</h1>
          <p>两只猫的吃货之旅, Meow~</p>

        </div>

      </div>
      <IceSunGallery :height=height id="gallery"/>
      <IceSunTimeLine :width="width" :height="height" />
      <SizeUtil @onWindowResize="onWindowResize" />
    </el-col>
  </el-row>

</template>

<script>
import IceSunGallery from "@/components/IceSunGallery";
import SizeUtil from "@/components/SizeUtil";
import IceSunBarrageX from "@/components/IceSunBarrageX";
import IceSunTimeLine from "@/components/IceSunTimeLine";
export default {
  name: "Index",
  components: {IceSunTimeLine, IceSunBarrageX, SizeUtil, IceSunGallery},
  data(){
    return {
      height: 0,
      width: 0,
      barrageArr: [
          '喵食不是卖猫粮的',
          '',
          '',
          '喵了个咪',
          '喵喵喵???',
          '',
          '喵今天在做什么?',
          '喵, 希望今天天气还不错'
      ]
    }
  },
  methods: {
    onWindowResize(h, w){
      this.height = h
      this.width = w
      console.log('Window Resize', h, w)
    },
    getRandomBarrageArr(){
      //console.log("getRandomBarrageArr start")
      let randomBarrageArr = []
      let tempBarrageArr = JSON.parse(JSON.stringify(this.barrageArr))
      while (tempBarrageArr.length > 0){
        const index = this.randomInt(0, tempBarrageArr.length - 1)
        const tempBarrageItem = tempBarrageArr[index]
        tempBarrageArr.splice(index, 1)
        randomBarrageArr.push(tempBarrageItem)
      }
      console.log(randomBarrageArr)
      //console.log("getRandomBarrageArr end")
      return randomBarrageArr
    },
    randomInt(min, max){
      return Math.round(Math.random()*(max - min) + min)
    },
  }
}
</script>

<style scoped>
</style>
