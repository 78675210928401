<template>
<!--  :default-active="activeIndex2"-->
<!--  @select="handleSelect"-->
  <el-menu
      :default-active="activeMenuItemIndex"
      @select="onMenuItemSelect"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#FFFFFF">
    <el-menu-item class="is-menu-item" index="/index" >喵世纪</el-menu-item>
    <el-submenu index="2">
      <template slot="title">喵见闻</template>
      <el-menu-item index="/article-list">出去玩</el-menu-item>
      <el-menu-item disabled index="2-2">出去吃</el-menu-item>
      <el-menu-item disabled index="2-3">在家吃</el-menu-item>
<!--      <el-submenu index="2-4">-->
<!--        <template slot="title">选项4</template>-->
<!--        <el-menu-item index="2-4-1">选项1</el-menu-item>-->
<!--        <el-menu-item index="2-4-2">选项2</el-menu-item>-->
<!--        <el-menu-item index="2-4-3">选项3</el-menu-item>-->
<!--      </el-submenu>-->
    </el-submenu>
    <el-menu-item class="is-menu-item" index="/article-edit">喵窝</el-menu-item>
    <el-menu-item index="4" disabled>给喵打Call</el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "IceSunHeader",
  data(){
    return {
      activeMenuItemIndex: "/index",
    }
  },
  mounted() {
    if (this.$router.currentRoute != null && this.$router.currentRoute.path != null){
      console.log('IceSunHeader', 'mounted', this.$router.currentRoute.path)
      this.activeMenuItemIndex = this.$router.currentRoute.path
    }
  },
  methods: {
    onMenuItemSelect(index){
      console.log("onMenuItemSelect", index, this.$router.currentRoute.path)
      if (index !== this.$router.currentRoute.path){
        this.$router.push({path: index})
      }

        // this.activeMenuItemIndex = this.$router.currentRoute.meta.menuItemIndex
    },
  },
}
</script>

<style scoped>
.el-menu-demo{
  font-family: 仓耳渔阳体3,serif;
}

.is-menu-item{
  display: inline;
}

.is-menu-item a{
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none;
}

</style>
