<template>
  <el-row class="multi-editor">
<!--    , borderBottom: '1px solid #CCCCCC'-->
    <!-- 图片上传组件辅助-->
    <el-upload
        :style="{height: '0px', width: '0px', overflow: 'hidden', margin: '0px', padding: '0px'}"
        class="avatar-uploader"
        :action="uploadServerUrl"
        :headers="header"
        :show-file-list="false"
        :on-success="onImgUploadSuccess"
        :on-error="onImgUploadError"
        :before-upload="onBeforeImgUpload"
        :data="qnUploadImgData"
    >
    </el-upload>
    <!--富文本编辑器组件-->

    <quill-editor
        :style="{height: '100%', width: '100%'}"
        ref="quillEditor"
        v-model="content"
        :options="quillEditorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
        @change="onEditorChange($event)"
    >
    </quill-editor>
  </el-row>

</template>

<!--suppress JSUnusedLocalSymbols -->
<script>
/* eslint-disable no-unused-vars */


import { Quill, quillEditor } from 'vue-quill-editor'

// Add fonts to whitelist
var Font = Quill.import('formats/font');
// We do not add Aref Ruqaa since it is the default
Font.whitelist = ['仓耳渔阳体5', '仓耳渔阳体4', false, '仓耳渔阳体2', '仓耳渔阳体1', '快乐体'];
Quill.register(Font, true);

//IceSunNote: 2021/8/30 13:25 Quill工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  // [{'header': 1}, {'header': 2}, {'header': 3}, {'header': false},],               // custom button values
  [{'list': 'ordered'}, {'list': 'bullet'}],
  // [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
  [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
  // [{'direction': 'rtl'}],                         // text direction

  [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
  // [{'header': [1, 2, 3, 4, 5, 6, false]}],

  [{'color': []}, {'background': []}],          // dropdown with defaults from theme
  [{'font': Font.whitelist}],
  [{'align': []}],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
]

export default {
  name: "IceSunMultiEditor",
  components: {
    quillEditor,
  },
  props: {
    height: {
      default: 0,
      type: Number,
    },
    width: {
      default: 0,
      type: Number,
    },
  },
  data () {
    return {
      content: '', //IceSunNote: 2021/8/30 13:27 富文本内容
      quillEditorOption: {
        placeholder: '又有好吃好玩的东西了么, ˋ( ° ▽、° ) ',
        theme: 'snow',  // or 'bubble'
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.querySelector('.avatar-uploader input').click()
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      },
      quillUpdateImgLoadingStatus: false, //IceSunNote: 2021/8/30 14:05 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      uploadServerUrl: 'https://up-z1.qiniup.com',  //IceSunNote: 2021/8/30 14:05 上传服务器地址
      header: {},  //IceSunNote: 2021/8/30 14:05 有的图片服务器要求请求头需要有token之类的参数，写在这里
      qnUploadImgData: {
        token: '',
        file: null,
      },
      qnUploadProps: {
        key: '',
        cdnUrl: '',
        appendPath: '',
      }
    }
  },
  watch: {
    content(newValue){
      this.$emit('onContentChange', newValue)
    },
  },
  methods: {
    onEditorBlur(quill) {
      //console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      //console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      //console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      //console.log('editor change!', quill, html, text)
      this.content = html
    },
    //IceSunNote: 2021/8/30 14:15 上传图片前事件
    async onBeforeImgUpload(file) {
      //IceSunNote: 2021/8/30 14:15 显示loading动画
      this.quillUpdateImgLoadingStatus = true
      console.log('== beforeUpload ==')
      console.log(file)
      const isJPG = file.type === 'image/jpeg'
      const isGIF = file.type === 'image/gif'
      const isPNG = file.type === 'image/png'
      const isBMP = file.type === 'image/bmp'

      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error('上传图片必须是JPG/GIF/PNG/BMP 格式!')
        return false
      }
      const {token, key, cdnUrl, appendPath} = await this.getUploadToken()
      this.qnUploadImgData.token = token
      this.qnUploadImgData.file = file
      this.qnUploadProps.key = key
      this.qnUploadProps.cdnUrl = cdnUrl
      this.qnUploadProps.appendPath = appendPath
      return (isJPG || isGIF || isPNG || isBMP)


    },


    //IceSunNote: 2021/8/30 14:15 上传图片成功事件

    onImgUploadSuccess(res) {
      //IceSunNote: 2021/8/30 14:15 如果上传成功
      if (res !== null && res.key !== null) {
        //IceSunNote: 2021/8/30 14:15 获取光标所在位置
        let length = this.editor.getSelection().index;
        //IceSunNote: 2021/8/30 14:14 插入图片
        this.editor.insertEmbed(length, 'image', this.qnUploadProps.cdnUrl + res.key + this.qnUploadProps.appendPath)
        // 调整光标到最后
        this.editor.setSelection(length + 1)
      } else {
        this.$message.error('图片插入失败')
      }
      //IceSunNote: 2021/8/30 14:15 loading动画消失
      this.quillUpdateImgLoadingStatus = false
    },
    //IceSunNote: 2021/8/30 14:15 上传图片失败事件
    onImgUploadError(res, file) {
      console.log(res)
      console.log(file)
      //IceSunNote: 2021/8/30 14:15 loading动画消失
      this.quillUpdateImgLoadingStatus = false
      this.$message.error('图片插入失败')
    },

    //IceSunNote: 2021/8/30 15:06 获取上传Token/Key/CdnUrl/AppendPath
    async getUploadToken() {
      const {status, data} = await this.$axios.get('/backend/upload/token')
      if (status === 200 && data && data.token && data.key && data.cdnUrl && data.appendPath) {
        console.log(data)
        return data
      }
      return {}
    }
  },
  computed: {
    /**
     * Quill编辑器对象
     * @returns {*}
     */
    editor() {
      return this.$refs.quillEditor.quill
    }
  },
  mounted() {
    console.log('this is current quill instance object', this.editor)
  },
}
</script>

<style scoped>
</style>
