<template>
  <el-backtop>
    <div
        style="{
        height: 100%;
        width: 100%;
        background-color: rgb(84, 92, 100);
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-family: 仓耳渔阳体5;
      }"
    >
      UP
    </div>
  </el-backtop>
</template>

<script>
export default {
  name: "GoTop"
}
</script>

<style scoped>

</style>
